.containerLogo {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.containerLogo:hover {
  text-decoration: none;
  scale: 1.01;
  transition: 500ms ease;
}
.image {
  align-items: center;
  color: #fff;
  font-family: "Avenir Next", sans-serif;
}
.textLogo {
  color: #fff;
  text-decoration: none;
  width: 190px;
  margin-left: 5px;
  height: 30px;
  font-size: 29.388px;
  font-family: maven pro;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

/**
 * Styles for the mobile
 */
.containerLogoMobile {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  text-decoration: none;
}
.textLogoMobile {
  color: #fff;
  font-weight: 100;
  font-size: 28px;
  text-align: center;
  align-items: center;
  margin-left: 5px;
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .textLogoMobile {
    color: #fff;
    font-weight: 100;
    font-size: 48px;
    text-align: center;
    align-items: center;
    margin-left: 20px;
  }
}
