.Button {
  margin: 30px auto;
  height: 55px;
  padding-left: 50px;
  padding-right: 50px;
  font-family: "Maven Pro", sans-serif;
  border-radius: var(--spacing-spacing-value-3, 12px);
  color: var(--token-colors-surface-surface-secondary, #648586);
  border: none;
  background: var(--token-colors-status-interaction-elements, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: "Inter", sans-serif;
  font-family: "Maven Pro", sans-serif;
  transition: ease-in-out 0.2s;
}

.Button:hover {
  cursor: pointer;
  background: var(--slot-colors-brand-colors-base-slot-1, #84a8a9);
}
/**
 * Styles for the mobile
 */

