.icon {
	margin-right: 20px;
	margin-bottom: -5px;
}
.whatsappBbutton {
	text-decoration: none;
	color: #fff;
	font-family: "Inter", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
	width: 300px;
	height: 55px;
	flex-shrink: 0;
	border-width: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.12);
	margin-right: 10px;
}

.whatsappBbuttonMobile {
	color: #fff;
	cursor: pointer;
	width: 20vw;
	height: 55px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.12);
	
}

.whatsappBbutton:hover {
	background: transparent;
	text-decoration: none;
	border: white solid 1px;
}
@media (min-width: 641px) {
	/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
	.whatsappBbuttonMobile {
	  width: 10vw;
	}
  }