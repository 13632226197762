
.ContainerSectionHeroMobile{
	display: flex;
	background: url("https://bohocasasstorage.blob.core.windows.net/boho-casas/BackgroundLSFProjects.png");
	background-color: lightgray 50%;
    z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	flex-direction: column;
	text-align: center;
	color: #fff;
	position: relative;
}
.SectionHeroBodyMobile {
	display: flex;
	flex-direction: column;
	padding: 2rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	
}
.TextSectionHeroBodyMobile{
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

