.ContainerSectionHeroService {
  background-image: url(/public/images/services.png);
  height: 100vh;
  width: 100vw;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  background-color: #52787D;
}


.SectionContent {
  margin-top: 4rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  padding: 2rem;
  gap: 1rem;
  margin-bottom: 5rem;
  height: 70vw;
  
}

.SectionContent :nth-child(2) {
  font-size: 24px;
}