.loading_screen {
  position: absolute;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.deactivate{
    display: none;
}
.custom-loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 16px solid;
  border-color: #e4e4ed;
  border-right-color: #50777c;
  animation: s2 1s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;

}
@keyframes s2 {
  to {
    transform: rotate(1turn);
  }
}
