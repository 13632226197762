/* 
   Styles for the customSelect class 
   This class is used for styling the Select component in LanguageToggle.
   It sets the background color to transparent.
*/

.divcustomSelect {
   background-color: transparent;
   /* Setting the background color to transparent */
   margin-right: 7px;
}

.transparent-background {
   background-color: transparent !important; 
}

.MuiPaper-root .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded {
   background-color: transparent;
}

/* 
   Styles for the cardIcon class 
   This class is used for positioning an icon within a card element.
   It applies a relative positioning and adjusts the top and left offsets.
*/

.cardIcon {
   position: relative;
   /* Applying relative positioning */
   top: 33px;
   /* Setting the top offset to adjust icon position */
   left: 40px;
   /* Setting the left offset to adjust icon position */
}

.divCustomSelect {}