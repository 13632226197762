/**
 * Styles for the .Button class.
 *
 * This class defines the styling for a button component. It ensures consistency
 * across the application and follows best practices for maintainable styling.
 *
 * @author Bruno Paulon
 */
.Button {
  margin: 30px auto;
  height: 55px;
  padding-left: 50px;
  padding-right: 50px;
  font-family: "Maven Pro", sans-serif;
  border-radius: var(--spacing-spacing-value-3, 12px);
  color: var(--token-colors-surface-surface-secondary, #F2F2F2);
  border: none;
  background: var(--token-colors-status-interaction-elements, #648586);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: "Inter", sans-serif;
  font-family: "Maven Pro", sans-serif;
}

.Button:hover {
  display: inline-flex;
  height: 55px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  background: var(--slot-colors-brand-colors-base-slot-1, #84a8a9);
}

/**
 * Styles for the mobile
 */
.ButtonMobile {
  margin: 0.6rem;
  margin-bottom: 5rem;
  height: 70px;
  width: 90vw;
  padding-left: 50px;
  padding-right: 50px;
  font-family: "Maven Pro", sans-serif;
  border-radius: var(--spacing-spacing-value-3, 12px);
  color: var(--token-colors-surface-surface-secondary, #F2F2F2);
  border: none;
  background: var(--token-colors-status-interaction-elements, #648586);
  text-align: center;
  font-size: 19px;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-family: "Maven Pro", sans-serif;
}