:root {
	--base-foundations-slot-colors-brand-colors-base-slot-2: rgba(100,
			133,
			134,
			1);
	--base-foundations-slot-colors-brand-colors-base-slot-3: rgba(85,
			111,
			112,
			1);
	--base-foundations-slot-colors-grayscale-colors-grayscale-slot-6: rgba(35,
			39,
			47,
			1);
	--text-style-semibold-h3-font-family: "MavenPro-SemiBold", Helvetica;
	--text-style-semibold-h3-font-size: 40px;
	--text-style-semibold-h3-font-style: normal;
	--text-style-semibold-h3-font-weight: 600;
	--text-style-semibold-h3-letter-spacing: 0px;
	--text-style-semibold-h3-line-height: 48px;
	--tokens-token-colors-border-border-primary: var(--base-foundations-slot-colors-brand-colors-base-slot-3);
	--tokens-token-colors-status-interaction-elements: var(--base-foundations-slot-colors-brand-colors-base-slot-2);
	--tokens-token-colors-text-plain-text-light: var(--base-foundations-slot-colors-grayscale-colors-grayscale-slot-6);
}
.page-head {
	height: auto;
	display: flex;
	flex-direction: column;
	width: auto;
	margin: 0;
	gap: 8px;
	padding-bottom: 20px;
	justify-content: flex-start;
}
.box1 {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	gap: 22px;
}
.o-QUE-j-FIZEMOS {
	color: #51787d;
	font-family: "Maven Pro-Bold", Helvetica;
	font-size: 1rem;
	font-weight: 700;
	letter-spacing: 0;
	display: flex;
	justify-content: flex-start;
	line-height: 22px;
	white-space: wrap;
	width: auto;
}
.rectangle {
	background-color: #51787d;
	height: 1px;
	width: 64px;
}
.projetos {
	display: flex;
	margin-top: 1rem;
	color: #545454;
	text-align: center;
	font-family: "Maven Pro", sans-serif;
	font-size: 24px;
	font-weight: bold;
}