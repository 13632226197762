@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500;600&display=swap");

.container {
    max-width: 100%;
    display: flex;

    flex-wrap: wrap;
    justify-content: flex-start;
    margin: auto;
    background-color: #F2F2F2;
}

.img {
    width: 100%;
    height: 407.773px;
    object-fit: cover;
    border-radius: 32px;
}

.Card {
    width: 28%;
    position: relative;
    margin: 34px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.datePostAndLocation {
    margin-top: 11px;
    display: flex;
    justify-content: space-between;
    color: #564f4f;
    font-family: "Maven Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.titlePost {
    margin-top: 32px;
    color: #564f4f;
    font-family: "Maven Pro", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30.273px;
    /* 126.137% */
}

.label {
    position: absolute;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding-left: 5px;
    padding-right: 10px;
    height: 41px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 32px;
    background-color: #556f70;
    z-index: 1;
    top: 15px;
    /* Posiciona 10px a partir do topo do Card */
    right: 15px;
    /* Posiciona 10px a partir da direita do Card */
}

.labelText {
    width: 111.931px;
    color: #fff;
    text-align: center;
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pagination {
    padding-top: 72px;
    padding-bottom: 55px;
    justify-content: center;
    margin: auto;
    display: flex;
    width: 582px;
    min-width: 328px;
    justify-content: space-between;
    align-items: center;
}

/**
 * Styles for the mobile
 */
.CardMobile {
    width: 70vw;
    position: relative;
    margin: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.imgMobile {
    width: 70vw;
    height: 65vw;
    object-fit: cover;
    border-radius: 25px;
}

.labelMobile {
    position: absolute;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 35vw;
    height: 7vw;
    border-top-right-radius: 25px;
    background-color: #556f70;
    z-index: 1;
    top: 0;
    right: 0;
}

.textLabelMobile {
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: 600;
}

.datePostAndLocationMobile {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.titlePostMobile {
    margin-top: 1rem;
    text-align: start;
    font-weight: 600;
}

.paginationMobile {

    justify-content: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}