.Link{
    margin-top: 21.68px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.buttonSeeDetailsProject {
    margin-left: 5px;
    align-items: center;
    align-self: center;
	color: #556f70;
	font-family: Maven Pro;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
    text-decoration: none;
}
