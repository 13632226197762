.ContainerSectionHeroDetails {
    margin:0;
    display: flex;
    flex-direction:column;
    background: #51787d;
    position: relative;
    height: 100vh;
    width:100vw;
    justify-content:flex-start;
}

.SectionHeroBody {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.LineVertical {
    border-right: 1px solid rgba(255, 255, 255, 0.24);
    margin-top: 0;
    width: 20px;
    height: 100vh;
    opacity: 0.24;
    position: fixed;
}

.TextSectionHeroBodyDetails {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 28px;
    justify-content: center;
    height: 50%;
    width: 100%;
}

.RegularH4 {
    width: 90%;
    color: var(--token-colors-text-plain-text-dark, #fff);
    font-family: Maven Pro;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.LabelDetailsProjects {
    width: 100%;
    padding-top: 56px;
    display: flex;
    padding-left: 2%;
    justify-content: space-between;
    border-radius: 24px;
    padding-bottom: 82px;
}

.labelForContactUs {
    color: var(--token-colors-text-plain-text-light, #545454);
    font-family: Maven Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.ContactUs {
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.ButtonEnterContact {
    width: 276px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    text-decoration: none;
    text-align: center;
    height: 48px;
    justify-content: center;
    cursor: pointer;
    background-color: #648586;
    color: #fff;
    font-family: "MavenPro-Medium", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.InfoAndTags {
    padding-left: 2%;
    width: 100%;
    display: flex;
    height: auto;
    align-items: flex-start;
    justify-content: center;
    gap: 95px;
}

.Info {
    width: 40%;
    gap: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.legend {
    color: var(--token-colors-status-interaction-elements, #648586);
    font: 700 12px/20px Maven Pro;
}

.label {
    color: var(--token-colors-text-plain-text-light);
    font: 500 0.875em Maven Pro/1.5714em;
}

.Tags {
    width: 60%;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: space-around;
}


.ContainerTags {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.boxTags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    gap: 10px;
}

.tag {
    z-index: 1;
    text-align: center;
    display: inline-flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    word-wrap: normal;
    border-radius: 8px;
    background: var(--token-colors-surface-surface-secondary, #F2F2F2);
    font-family: "MavenPro-Medium", Helvetica;
}

.tag:hover {
    background: #648586;
    color: #F2F2F2;
}

.ContainerShare {
    width: 40%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
}

.AboutTheProject {
    color: var(--token-colors-text-plain-text-light, #545454);
    font-family: Maven Pro;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding-left: 2%;
    margin-top: 72px;
    padding-bottom: 18px;
}

.descriptionProject {
    padding-left: 2%;
    max-width: 1216px;
    color: var(--token-colors-text-plain-text-light, #545454);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}