@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500;600&display=swap");

.rootContainer{
    display: flex;
    flex-direction: column;
}
.containerBlog {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    background-color: #F2F2F2;
}

.img {
    width: 100%;
    height: 407.773px;
    object-fit: cover;
    border-radius: 32px;
    border-top-right-radius: 0;
}

.CardBlog {
    width: 30%;
    position: relative;
    margin: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.datePostAndLocation {
    margin-top: 11px;
    display: flex;
    justify-content: space-between;
    color: #545454;
    font-family: "Maven Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.titlePost {
    margin-top: 32px;
    color: #545454;
    font-family: "Maven Pro", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30.273px;
    /* 126.137% */
}


.labelText {
    width: 111.931px;
    color: #fff;
    text-align: center;
    font-family: "Maven Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/**
 * Styles for the mobile
 */
.CardMobile {
    width: 70vw;
    position: relative;
    margin: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: medium;
}

.imgMobile {
    width: 70vw;
    height: 65vw;
    object-fit: cover;
    border-radius: 25px;
}

.labelMobile {
    position: absolute;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 35vw;
    height: 7vw;
    border-top-right-radius: 25px;
    background-color: #51787d;
    z-index: 1;
    top: 0;
    right: 0;
}

.textLabelMobile {
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: 600;
}

.datePostAndLocationMobile {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: #545454;
}

.titlePostMobile {
    color: #545454;
    margin-top: 1rem;
    text-align: start;
    font-weight: 600;
}
.paginationMobile{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100vw;
    justify-content: center;
}