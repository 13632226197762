.ContainerSectionHeroRemodelingMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  background: url("https://bohocasasstorage.blob.core.windows.net/boho-casas/backgroundHeroSectionRemodeling.png");
  background-color: lightgray 50%;
  z-index: 1;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  justify-content: flex-start;
  text-align: center;
  position: relative;
}

.TextSectionHeroBodyMobile {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
