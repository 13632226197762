.contactUs {
  padding-top: 2rem;
  width: 100%;
  height: auto;
  background-color: #F2F2F2;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  padding-bottom: 2rem;
}

.container1 {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  text-align: left;
  width: 45%;
}

.container2 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 45%;
  align-items: center;
}

.contactUsMobile {
  width: 100vw;
  padding: 6vw;
  justify-content: center;
}

.container1Mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container2Mobile {
  margin-top: 2rem;
  padding:1rem;
}

.containerContactMobile {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}

.contactItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}