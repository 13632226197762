.carContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  background-color: #F2F2F2 !important;
  cursor: pointer;
  gap: 1%;
  margin: 1rem;
}

.cardItem {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: #545454;
  justify-content: center;
  align-items: center;
  width: 19%;
  min-height: 400px;
  height: auto;
  flex-shrink: 0;
  border-radius: 16px;
  box-shadow: 0px 4px 200px 0px rgba(0, 0, 0, 0.06);
  background-color: var(--surface-primary);
}

@media (max-width: 480px) {
  .cardItem {
    min-width: 90%;
    margin-bottom: 1rem;
  }
}

@media (min-width: 481px) and (max-width: 900px) {
  .cardItem {
    min-width: 30%;
    margin: 0.5rem;
  }
}



.circle {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 100%;
  background-color: var(--token-colors-surface-surface-success, #edfdf8);
}

.cardItem:hover {
  color: #fff;
  background: #51787d;
  transition: 300ms ease-out;
  border-radius: 24px 8px 24px 24px;
}


.titleCard {
  margin: auto;
  font-family: "Maven Pro", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #545454;
  width: 100%;
  text-align: center;
}

.textDescription {
  margin: auto;
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #545454;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
}

.carContainerMobile {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 641px) {
  .carContainerMobile {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}