:root {
	--base-foundations-slot-colors-brand-colors-base-slot-2: rgba(
		100,
		133,
		134,
		1
	);
	--base-foundations-slot-colors-brand-colors-base-slot-3: rgba(
		85,
		111,
		112,
		1
	);
	--text-style-bold-h6-font-family: "MavenPro-Bold", Helvetica;
	--text-style-bold-h6-font-size: 20px;
	--text-style-bold-h6-font-style: normal;
	--text-style-bold-h6-font-weight: 700;
	--text-style-bold-h6-letter-spacing: 0px;
	--text-style-bold-h6-line-height: 28px;
	--tokens-token-colors-border-border-primary: var(
		--base-foundations-slot-colors-brand-colors-base-slot-3
	);
	--tokens-token-colors-status-interaction-elements: var(
		--base-foundations-slot-colors-brand-colors-base-slot-2
	);
}

.access-area-menu-big {
	position: relative;
	height: 32px;
	width: 308px;
	right: 0;
	display: flex;
	justify-content: center;
}

.access-area-menu-big .frame {
	align-items: center;
	display: inline-flex;
	gap: 8px;
	justify-content: flex-end;
	position: relative;
}

.access-area-menu-big .rectangle {
	background-color: var(--tokens-token-colors-border-border-primary);
	height: 1px;
	position: relative;
	width: 24px;
}

.access-area-menu-big .VER-DETALHES-DO {
	color: #51787d;
	font-family: "Maven pro";
	font-size: 1rem;
	font-style: var(--text-style-bold-h6-font-style);
	font-weight: var(--text-style-bold-h6-font-weight);
	letter-spacing: var(--text-style-bold-h6-letter-spacing);
	line-height: var(--text-style-bold-h6-line-height);
	margin-top: -1px;
	position: relative;
	text-align: right;
	white-space: nowrap;
	width: fit-content;
	text-decoration: none;
}
.VER-DETALHES-DO-MOBILE{
	text-decoration: none;
	color: #51787d;
}