.ContainerSectionHero {
	display: flex;
	background: url("https://bohocasasstorage.blob.core.windows.net/boho-casas/backgroundHeroSectionProjectsAndWorks.png");
	background-size: cover;
	background-repeat: no-repeat;
	flex-direction: column;
	background-position: center;
	position: relative;
	justify-content: flex-start;
	height: 100vh;
}

.filter {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.TextSectionHeroBodyMobile {
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 20px;
	padding: 2rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	height: 50vh;
}

.RegularH4Mobile {
	color: var(--token-colors-text-plain-text-dark, #fff);
	font-family: Maven Pro;

}