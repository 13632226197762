
.ContainerSectionHeroMobile {
	display: flex;
	background: url("https://bohocasasstorage.blob.core.windows.net/boho-casas/backgroundProjectsResidentials.png");
	background-color: lightgray 50%;
    z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	flex-direction: column;
	position: relative;
}
.filter {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.SectionHeroBodyMobile{
	text-align: center;
	color: white;
}
.TextSectionHeroBodyMobile{
	margin-top: 5rem;
	margin-bottom: 5rem;
}