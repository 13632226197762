/* This is a comment explaining the purpose of the following CSS code */

@media screen and (min-width: 900px) {
	.RegularH1 {
		/* This property sets the color of the text to a custom CSS variable value, 
		 which falls back to the hex color #fff if the variable is not defined */
		color: var(--token-colors-text-plain-text-dark, #fff);

		/* This property sets the font family to "Maven Pro" */
		font-family: Maven Pro;

		/* This property sets the font size to 56 pixels */
		font-size: 56px;

		/* This property sets the font style to normal, meaning it's not italic or oblique */
		font-style: normal;

		/* This property sets the font weight to 400, which is considered normal */
		font-weight: 400;

		/* This property sets the line height to 64 pixels */
		line-height: 64px;
	}
}

@media screen and (max-width: 900px) {
	.RegularH1 {
		font-family: Maven Pro;
		font-size: 56px;
		font-style: normal;
		font-weight: 400;
		line-height: 64px; /* 114.286% */
	}
}

@media screen and (max-width: 600px) {
	.RegularH1 {
		text-align: center;

		font-family: Maven Pro;
		font-size: 32px;
		font-style: normal;
		font-weight: 400;
		line-height: 40px; /* 125% */
	}
}
