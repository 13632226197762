.rootMobile {
  margin: 0 auto;
  margin-top: 1rem;
}
.carouselMobile {
}

.cardMobile {
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: cover;
  width: 74vw;
  border-radius: 16px;
  margin: 1rem;
}
.cardIconsMobile{
  position: relative;
  bottom: 20px;
}
.cardTextMobile{
  margin-top: 20%;
}
.cardContentMobile {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-left: 1rem;
}

.buttonSliderMobile {
  border: solid 1px white;
  border-radius: 100%;
  width: 7vw;
  height: 7vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonSliderMobile2 {
  background-color: rgb(255, 255, 255);
  border-radius: 100%;
  width: 2.5vw;
  height: 2.5vw;
}
.workerDescription{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 1rem;
  gap: 1rem;
  color: white;
  font-size: 17px;
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .rootMobile {
    margin: 0;
  }
  .carouselMobile {
    display: flex;
    margin: 0 auto;
  }
  .cardMobile {
    margin: 1rem;
    width: 30vw;
  }
  .cardContentMobile{
    padding-left: 1.5rem;
  }
  .cardTextMobile{
    margin-top: 70%;
  }
  .cardIconsMobile{
    position: relative;
    bottom: -25%;
  }
  .cardTextMobile p{
    font-size: x-large;
  }
}
