.ContainerSection {
  background-color: #f2f2f2;
  position: relative;
  padding-top: 2rem;
}

@media (max-width: 1440px) and (min-width: 330px) {
  .ContainerSection {
    padding-top: 2rem;
  }
}

.Pagehead {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding-left: 52px;
}

.ContainerSectionMobile {
  background-color: #F2F2F2;
  padding-top: 2rem;
}