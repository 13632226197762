.rootContainer {
	height: 100px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #545454;
}

.firstLine {
	height: 40%;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.secondLine {
	height: 40%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;
}

.line {
	height: 1px;
	width: 64px;
	background-color: #51787d;
	margin-left: 50px;
	margin-right: 20px;
}

.lineTwo {
	height: 2px;
	width: 32px;
	background-color: #51787d;
	margin-left: 50px;
	margin-right: 20px;
}

.seeAlls {
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-right: 50px;
}
.rootMobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.firstLineMobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.lineMobile {
	height: 1px;
	width: 15vw;
	background-color: #51787d;
	margin-bottom: 0.7rem;
}
.secondLineMobile p {
	font-size: 32px;
	width: 85%;
	margin: auto;
}
