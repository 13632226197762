.ContainerSectionHeroMobile {
  display: flex;
  height: 100vh;
  background: url("https://bohocasasstorage.blob.core.windows.net/boho-casas/backgroundHeroSectionProjectsAndWorks.png");
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  background-position: center;
  text-align: center;
  position: relative;
}

.ContainerComercialContentMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  color: white;
  gap: 1rem;
  z-index: 1;
}

.RegularH4Mobile {
  color: var(--token-colors-text-plain-text-dark, #fff);
  font-family: Maven Pro;
}
