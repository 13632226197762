.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 97%;
  align-self: center;
  z-index: 3;
  margin-top: 5px;
}

.firstColm {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
}

.secondColm {
  display: flex;
  flex: 2;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.thirdColm {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}
/**
 * Styles for the mobile
 */
.headerMobile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 12%;
  z-index:99;
  border-bottom: solid rgba(255, 255, 255, 0.136);
}
.headerItem1Mobile {
  flex: 2;
}
.headerItem2Mobile {
  flex: 1;
}
.headerItem3Mobile {
  flex: 0.2;
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .headerItem2Mobile {
    flex: .6;
  }
}
