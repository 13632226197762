.playvideo {
  display: flex;
  -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
  background-color: rgba(255, 255, 255, 0.44);
  border-radius: 200px;
  animation: pulsate-fwd 1s ease-in-out infinite both;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  
}


.buttonSliderMobile {
  border: solid 1px white;
  border-radius: 100%;
  width: 7vw;
  height: 7vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonSliderMobile2 {
  background-color: rgb(255, 255, 255);
  border-radius: 100%;
  width: 2.5vw;
  height: 2.5vw;
}

.sliderMobile {
  text-align: center;
}

@media (min-width: 641px) {

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .buttonSliderMobile2 {
    width: 2vw;
    height: 2vw;
  }

  .buttonSliderMobile {
    width: 6vw;
    height: 6vw;
  }
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}