.buttonMobileAll {
  border-radius: 12px;
  border: 1px solid #51787d;
  padding: 1rem;
  width: 80vw;
  margin: 0 auto;
  margin-top: 1.5rem;
  text-align: center;
  color: #51787d;
}
