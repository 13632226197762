.Step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto 2%;
  width: 300px;
  height: 300px;
  text-align: center;
}

.Step:hover {
  cursor: pointer;
  transition: 500ms ease;
}

.StepNumber {
  color: #565656;
  font-size: 72px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 80px;
  opacity: 0.2;
  white-space: nowrap;

  font-family: "Maven Pro-Regular", Helvetica;
}
@media (max-width: 480px){
  .StepDescription {
    font-size: 18px !important;
  }
}
.StepDescription {
  color: #565656;
  font-family: "Maven Pro-Regular", Helvetica;
  font-size: 24px;
}

.icons {
  width: 100px;
  cursor: pointer;
}

.icons:hover {

  background-color: #51787d;
  transition: 500ms ease;
  border-radius: 50%;

}