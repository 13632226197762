
.containerSectionHome {
	display: flex;
	background-color: #52787D;
}
.section {
	display: flex;
	/** Displaying the section content in a flex container with vertical arrangement. */
	display: flex;
	flex-direction: column;
	/** Setting the height of the section to cover the entire viewport height. */
	height: 100vh;
	/** Setting the width of the section to cover the entire viewport width. */
	width: 100vw;
}

.lineVertical {
	border-right: 1px solid rgba(255, 255, 255, 0.24); /* Cor da borda com opacidade */
	width: 20px;
	height: 100vh;
	opacity: 0.24;
	z-index: 1;
}
.lineVertical2 {
	border-left: 1px solid rgba(255, 255, 255, 0.24); /* Cor da borda com opacidade */
	width: 20px;
	height: 100vh;
	opacity: 0.24;
	z-index: 1;
}

.lineHorizontal {
	z-index: 1;
	border-bottom: 1px solid rgba(255, 255, 255, 0.24); /* Cor da borda com opacidade */
	margin-top: 30px;
	position: absolute;
	width: 100vw;
	height: 49px;
	opacity: 0.24;
}
/**
 * Styles for the mobile
 */
 .containerSectionHomeMobile{
	display: flex;	
	background-color: #52787D;
 }
 .sectionMobile {
	display: flex;
	flex-direction: column;
	height: 100vh;
}
