.containerRemodeling {
	background-color: white;
	padding: 72px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	text-align: justify;
}

.containerRemodeling>p {
	font-size: 24px;
}

.ImageRemodeling1 {
	background-image: url(https://bohocasasstorage.blob.core.windows.net/boho-casas/oakberry.jpg);
	background-size: cover;
	background-position: center;
	border-radius: 18.98px;
	height: 440px;
	left: 0;
	position: absolute;
	top: 0;
	width: 397px;
}

.ImageRemodeling2 {
	background-image: url(https://bohocasasstorage.blob.core.windows.net/boho-casas/arco.jpg);
	background-size: cover;
	background-position: center;
	border-radius: 18.98px;
	height: 440px;
	left: 104px;
	position: absolute;
	top: 222px;
	width: 397px;
}