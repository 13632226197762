.ContainerSectionHeroCommercial {
	display: flex;
	background: url("https://bohocasasstorage.blob.core.windows.net/boho-casas/SectionHeroCommercialAndFranchiesesBackground.png");
	background-color: lightgray 50%;
    z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
}

.SectionHeroBody {
	display: flex;
	flex-direction:column;
	height:100vh;
	width:100vw;
}

.LineVertical {
	border-right: 1px solid rgba(255, 255, 255, 0.24);
	margin-top: 0;
	width: 50px;
	height: 100vh;
	opacity: 0.24;
}

.LineVertical2 {
	border-left: 1px solid rgba(255, 255, 255, 0.24);
	margin-top: 0;
	width: 50px;
	height: 100vh;
	opacity: 0.24;
}

.LineHorizontal {
	z-index: 1;
	border-bottom: 1px solid rgba(255, 255, 255, 0.24);
	margin-top: 30px;
	position: absolute;
	width: 100vw;
	height: 49px;
	opacity: 0.24;
}

.TextSectionHeroBody {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
	margin-left: 28px;
	justify-content: center;
	height: 50%;
	width: 90%;
}

.RegularH4 {
	width: 90%;
	color: var(--token-colors-text-plain-text-dark, #fff);
	font-family: Maven Pro;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;
}