.BoxWithContainer {
  height: 264px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;

}

.BoxWithContainer .items {
  height: 264px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;

}

.BoxWithContainer .element {
  height: 264px;
  width: 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
}

.number {
  -webkit-text-stroke: 1px #556f70;
  color: transparent;
  font-family: "Maven Pro-Regular", Helvetica;
  font-size: 72px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 80px;
  position: relative;
  top: 10%;
  white-space: nowrap;
  z-index: 1;
}


.BoxWithContainer .text-wrapper-briefing {
  color: #545454;
  font-family: "Maven Pro-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 9px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  top: 184px;
  white-space: nowrap;
}

.BoxWithContainer .div {
  color: #545454;
  font-family: "Maven Pro-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  top: 220px;
  width: 200px;
}

.BoxWithContainer .overlap-group {
  height: 172px;
  left: 40px;
  top: -1px;
  width: 120px;
}

.BoxWithContainer .text-wrapper-2 {
  -webkit-text-stroke: 1px #556f70;
  color: transparent;
  font-family: "Maven Pro-Regular", Helvetica;
  font-size: 72px;
  font-weight: 400;
  left: 22px;
  letter-spacing: 0;
  line-height: 80px;
  opacity: 0.2;
  top: 0;
  white-space: nowrap;
}

.BoxWithContainer .icon {
  background-color: #f8f6f6;
  border-radius: 50%;
  padding: 3rem;
  margin-bottom: 1rem;
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.BoxWithContainer .icon:hover {
  background-color: rgb(84, 111, 112);
  transition: 500ms ease;
  border-radius: 60px;
  height: 120px;
  left: 0;
  top: 52px;
  width: 120px;
}

.BoxWithContainer .icon-instance-node {
  height: 40px !important;
  left: 40px !important;
  position: absolute !important;
  top: 40px !important;
  width: 40px !important;
}

.BoxWithContainer .element-2 {
  height: 264px;
  left: 330px;
  top: 0;
  width: 206px;
}


.BoxWithContainer .element-3 {
  height: 264px;
  left: 660px;
  top: 0;
  width: 206px;
}

.description {
  color: #545454;
  font-family: "Maven Pro-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  width: 200px;
  white-space: pre-wrap;
}

.title {
  padding-top: 8px;
  color: #545454;
  font-family: "Maven Pro-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  white-space: wrap;

}