.ContainerAuth {

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  background-color: #50777c;
  color: #50777c;
  overflow: hidden;
}

.ContainerAuth img {

  width: 15vw;
}

.ContainerAuth form {
  background-color: #F2F2F2;
  height: 100vh;
  padding: 10rem 5rem;
}

.ContainerAuth form h1 {
  color: #50777c;
  padding-bottom: 2rem;
}

.AuthTextTitle {
  font-family: "Maven Pro-Bold", Helvetica;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 44px;
  text-align: center;
  color: #F2F2F2;
}

.buttonLogin {
  width: 300px;
  height: 50px;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 16px;
  font-weight: bold;
}