@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");

:root {
	--base-foundations-slot-colors-brand-colors-base-slot-2: rgba(100, 133, 134, 1);
	--base-foundations-slot-colors-grayscale-colors-grayscale-slot-6: rgba(35, 39, 47, 1);
	--text-style-bold-h4-font-family: "MavenPro-Bold", Helvetica;
	--text-style-bold-h4-font-size: 32px;
	--text-style-bold-h4-font-style: normal;
	--text-style-bold-h4-font-weight: 700;
	--text-style-bold-h4-letter-spacing: 0px;
	--text-style-bold-h4-line-height: 40px;
	--text-style-bold-label-font-family: "MavenPro-Bold", Helvetica;
	--text-style-bold-label-font-size: 14px;
	--text-style-bold-label-font-style: normal;
	--text-style-bold-label-font-weight: 700;
	--text-style-bold-label-letter-spacing: 0px;
	--text-style-bold-label-line-height: 22px;
	--tokens-token-colors-status-interaction-elements: var(--base-foundations-slot-colors-brand-colors-base-slot-2);
	--tokens-token-colors-text-plain-text-light: var(--base-foundations-slot-colors-grayscale-colors-grayscale-slot-6);

	/* Grayscale Colors  */
	/* Fundação para construção dos tokens de cores neutras */

	--Grayscale-Slot-1: #ffffff;
	--Grayscale-Slot-2: #f8f9fc;
	--Grayscale-Slot-3: #f1f3f9;
	--Grayscale-Slot-4: #e1e6ef;
	--Grayscale-Slot-5: #3f444d;
	--Grayscale-Slot-6: #545454;
	--Grayscale-Slot-7: #1b1f27;
	--Grayscale-Slot-8: #0a0d14;
	/* Base Colors */
	/* Fundação para tokens focados em elementos de destaque e interação */
	--Base-Slot-1: #84a8a9;
	--Base-Slot-2: #648586;
	--Base-Slot-3: #556f70;
	--Base-Slot-4: #496061;
	/* Success Feedback Color */
	/* Fundação para aplicação de Tokens com foco em sucesso e feedbacks positivo */
	--Success-Slot-1: #edfdf8;
	--Success-Slot-2: #08875d;
	--Success-Slot-3: #04724d;
	--Success-Slot-4: #066042;
	/* Warning Feedback Color */

	/* Fundação para aplicação de Tokens com foco em Aviso e feedbacks neutros */
	--Warning-Slot-1: #F2F2F2;
	--Warning-Slot-2: #b25e09;
	--Warning-Slot-3: #96530f;
	--Warning-Slot-4: #80460d;
	/* Danger Feedback Color */
	/* Fundação para aplicação de Tokens com foco em Erro e feedbacks negativos  */
	--Danger-Slot-1: #fef1f2;
	--Danger-Slot-2: #e02d3c;
	--Danger-Slot-3: #ba2532;
	--Danger-Slot-4: #981b25;

	/* Tokens Colors / Text */
	--token-colors-plain-text-light: var(--Grayscale-Slot-6);
	--token-colors-plain-text-dark: var(--Grayscale-Slot-1);

	/* Token Colors / Surface  */

	--surface-primary: var(--Grayscale-Slot-1);
	--surface-secondary: var(--Grayscale-Slot-2);
	--surface-tertiary: var(--Base-Slot-3);
	--surface-sucess: var(--Success-Slot-1);
	--surface-error: var(--Danger-Slot-1);
	--surface-warning: var(--Warning-Slot-1);

	/* Token Colors / Border */

	--border-primary: var(--Base-Slot-3);
	--border-secondary: var(--Grayscale-Slot-1);

	/* Token Colors /Behavior */

	--behavior-sucess: var(--Success-Slot-3);
	--behavior-error: var(--Danger-Slot-3);
	--behavior-warning: var(--Warning-Slot-3);

	/* Token Colors / Status */

	--enable-elements: var(--Grayscale-Slot-6);
	--disable-elements: var(--Grayscale-Slot-4);
	--interaction-elements: var(--Base-Slot-2);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Maven Pro", sans-serif;
}
html, body {
	scroll-behavior: smooth;
  }