@import url("https://fonts.googleapis.com/css2?family=Inter&family=Maven+Pro:wght@700&display=swap");

.TitleSliders {
	color: #556f70;
	text-align: center;
	font-family: "Maven Pro", sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 16.45px;

}



/* Adicione estas classes para estilizar os bullets de navegação */
.bulletContainer {
	background-color: inset;
	list-style: none;
	display: flex;
	justify-content: center;
	margin-top: 16px;
}

.bullet {
	width: 10px;
	height: 10px;
	background-color: #ccc;
	border-radius: 50%;
	margin: 0 5px;
	cursor: pointer;
}

.activeBullet {
	width: 10px;
	height: 10px;
	background-color: #556f70;
	border-radius: 50%;
	margin: 0 5px;
	cursor: pointer;
}

.descriptionSliderMobile p:first-child {
	font-weight: bolder;
	margin-top: 1rem;
}

.descriptionSliderMobile p:last-child {
	font-weight: bolder;

}