@import url("https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap");

.services {
  background-color: #F2F2F2 !important;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.offertext {
  margin-top: 1rem;
  color: #51787d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.75rem;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
}

.offertext::before {
  content: "";
  position: relative;
  width: 5rem;
  height: 1px;
  background-color: #556f70;
}

.textOurServices {
  margin-top: 1rem;
  color: #545454;
  text-align: center;
  font-family: "Maven Pro", sans-serif;
  font-size: 2rem;
  font-weight: bold;
}

.textDescription {
  width: 849px;
  margin-top: 1rem;
  color: #545454;
  text-align: center;
  font-family: "Maven Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
}

.servicesMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F2F2F2;
}

.offertextMobile {
  margin-top: 1rem;
  color: #51787d;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.75rem;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
}

.offertextMobile::before {
  content: "";
  position: relative;
  width: 5rem;
  height: 1px;
  background-color: #556f70;
}

.ourServicesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.textOurServicesMobile {
  margin-top: 1rem;
  color: #545454;
  font-family: "Maven Pro", sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.textDescriptionMobile {
  margin: 1rem;
}