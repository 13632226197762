.cookies-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    background-color: #51787d;
    z-index: 10;
    width: 100vw;
    height: 90vh;
    position: fixed;
    padding-top: 30px;
    bottom: 0;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    box-shadow: #435a5bda 10px 10px 10px;
    overflow: scroll;
}

.textH2Mobile {
    padding: 0 1%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: white;
    text-align: center;
    line-height: normal;
    font-size: 2rem;
}

.cookiesParagraphMobile {
    color: white;
    text-align: justify;
    text-align: center;
    line-height: initial;
    font-size: 1rem;
    margin: auto;
    width: 90vw;
    padding: 0 1%;
}


.ButtonCustomize-mobile {
    display: flex;
    width: 90vw;
    justify-self: center;
    margin: auto;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.ButtonCustomize-mobile:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-negative, #FFF);
    color: var(--token-colors-status-interaction-elements, #648586);
}

.ButtonAccept-mobile {
    display: flex;
    width: 90vw;
    justify-self: center;
    margin: auto;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.ButtonAccept-mobile:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-negative, #FFF);
    color: var(--token-colors-status-interaction-elements, #648586);
}

.ButtonReject-mobile {
    display: flex;
    width: 90vw;
    justify-self: center;
    margin: auto;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.ButtonReject-mobile:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-negative, #FFF);
    color: var(--token-colors-status-interaction-elements, #648586);
}

.ButtonAcceptAllModal {
    display: inline-flex;
    width: 157px;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro ;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    margin-top: 16px;
}

.ButtonAcceptAllModal:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-elements, #435a5bda);
    color: var(--token-colors-status-interaction-negative, #FFF);
}

.ButtonRejectAllModal {
    display: inline-flex;
    width: 157px;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    margin-top: 16px;
}

.ButtonRejectAllModal:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-elements, #435a5bda);
    color: var(--token-colors-status-interaction-negative, #FFF);
}