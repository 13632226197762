:root {
    --base-foundations-slot-colors-brand-colors-base-slot-1: rgba(132, 168, 169, 1);
    --base-foundations-slot-colors-brand-colors-base-slot-2: rgba(100, 133, 134, 1);
    --base-foundations-slot-colors-brand-colors-base-slot-4: rgba(73, 96, 97, 1);
    --base-foundations-slot-colors-grayscale-colors-grayscale-slot-1: rgba(255, 255, 255, 1);
    --base-foundations-slot-colors-grayscale-colors-grayscale-slot-4: rgba(225, 230, 239, 1);
    --text-style-medium-label-font-family: "MavenPro-Medium", Helvetica;
    --text-style-medium-label-font-size: 14px;
    --text-style-medium-label-font-style: normal;
    --text-style-medium-label-font-weight: 500;
    --text-style-medium-label-letter-spacing: 0px;
    --text-style-medium-label-line-height: 22px;
    --tokens-token-colors-status-disable-elements: var(--base-foundations-slot-colors-grayscale-colors-grayscale-slot-4);
    --tokens-token-colors-status-interaction-elements: var(--base-foundations-slot-colors-brand-colors-base-slot-2);
    --tokens-token-colors-status-interaction-negative: var(--base-foundations-slot-colors-grayscale-colors-grayscale-slot-1);
}

.button {
    margin: auto;
    all: unset;
    align-items: center;
    border-radius: 12px;
    box-sizing: border-box;
    gap: 8px;
    height: 48px;
    justify-content: center;
    padding: 8px 16px;
    cursor: pointer;
}

.button .text-wrapper {
    font-family: var(--text-style-medium-label-font-family);
    font-size: var(--text-style-medium-label-font-size);
    font-style: var(--text-style-medium-label-font-style);
    font-weight: var(--text-style-medium-label-font-weight);
    letter-spacing: var(--text-style-medium-label-letter-spacing);
    line-height: var(--text-style-medium-label-line-height);
    position: relative;
    white-space: wrap;
    text-decoration: none;
    width: fit-content;
}

.button .class {
    height: 24px !important;
    margin-left: -4px !important;
    margin-right: -4px !important;
    position: relative !important;
    width: 24px !important;
}

.button.only-icon {
    display: flex;
    width: 48px;
}

.button.right {
    display: inline-flex;
}

.button.disabled {
    background-color: var(--tokens-token-colors-status-interaction-negative);
}

.button.left {
    display: inline-flex;
}

.button.one {
    display: inline-flex;
}

.button.dark {
    background-color: var(--base-foundations-slot-colors-brand-colors-base-slot-4);
}

.button.light {
    background-color: var(--base-foundations-slot-colors-brand-colors-base-slot-1);
}

.button.primary {
    background-color: var(--tokens-token-colors-status-interaction-elements);
}

.button.secondary {
    background-color: #F8F6F6;
    color: #648585;
}

.button.disabled .text-wrapper {
    color: var(--tokens-token-colors-status-disable-elements);
}

.button.dark .text-wrapper {
    color: var(--tokens-token-colors-status-interaction-negative);
}

.button.light .text-wrapper {
    color: var(--tokens-token-colors-status-interaction-negative);
}

.button.primary .text-wrapper {
    color: var(--tokens-token-colors-status-interaction-negative);
}

.button.secondary .text-wrapper {
    color: #648585;

}