:root {
    --base-foundations-slot-colors-brand-colors-base-slot-1: rgba(132, 168, 169, 1);
    --base-foundations-slot-colors-brand-colors-base-slot-2: rgba(100, 133, 134, 1);
    --base-foundations-slot-colors-grayscale-colors-grayscale-slot-1: rgba(255, 255, 255, 1);
    --text-style-medium-label-font-family: "MavenPro-Medium", Helvetica;
    --text-style-medium-label-font-size: 14px;
    --text-style-medium-label-font-style: normal;
    --text-style-medium-label-font-weight: 500;
    --text-style-medium-label-letter-spacing: 0px;
    --text-style-medium-label-line-height: 22px;
    --text-style-regular-h6-font-family: "MavenPro-Regular", Helvetica;
    --text-style-regular-h6-font-size: 20px;
    --text-style-regular-h6-font-style: normal;
    --text-style-regular-h6-font-weight: 400;
    --text-style-regular-h6-letter-spacing: 0px;
    --text-style-regular-h6-line-height: 28px;
    --text-style-semibold-h5-font-family: "MavenPro-SemiBold", Helvetica;
    --text-style-semibold-h5-font-size: 24px;
    --text-style-semibold-h5-font-style: normal;
    --text-style-semibold-h5-font-weight: 600;
    --text-style-semibold-h5-letter-spacing: 0px;
    --text-style-semibold-h5-line-height: 32px;
    --tokens-token-colors-status-interaction-elements: var(--base-foundations-slot-colors-brand-colors-base-slot-2);
    --tokens-token-colors-status-interaction-negative: var(--base-foundations-slot-colors-grayscale-colors-grayscale-slot-1);
    --tokens-token-colors-surface-surface-primary: var(--base-foundations-slot-colors-grayscale-colors-grayscale-slot-1);
    --tokens-token-colors-text-plain-text-dark: var(--base-foundations-slot-colors-grayscale-colors-grayscale-slot-1);
}

.cookies-web {
    flex-direction: row;
    height: auto;
    width: 100%;
    align-items: center;
    z-index: 10;
    display: flex;
    position: fixed;
    bottom: 0;
    justify-content: space-between;
    background-color: #51787D;
    margin: auto;
    justify-self: center;
    padding: 2em;
}



.cookiesparagraph {
    padding-top: 4px;
    color: #ffffff;
    font-family: "Maven Pro-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    line-height: 28px;
    text-align: justify;
    white-space: wrap;
    word-break: break-word;
}

.ButtonAccept {
    display: inline-flex;
    width: 157px;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.ButtonAccept:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-negative, #FFF);
    color: var(--token-colors-status-interaction-elements, #648586);
}

.ButtonReject {
    display: inline-flex;
    width: 157px;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.ButtonReject:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-negative, #FFF);
    color: var(--token-colors-status-interaction-elements, #648586);
}

.ButtonCustomize {
    display: inline-flex;
    width: 157px;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.ButtonCustomize:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-negative, #FFF);
    color: var(--token-colors-status-interaction-elements, #648586);
}

.ButtonAcceptAllModal {
    display: inline-flex;
    width: 157px;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    margin-top: 16px;
}

.ButtonAcceptAllModal:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-elements, #435a5bda);
    color: var(--token-colors-status-interaction-negative, #FFF);
}

.ButtonRejectAllModal {
    display: inline-flex;
    width: 157px;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--token-colors-status-interaction-elements, #648586);
    color: var(--token-colors-status-interaction-negative, #FFF);
    font-family: Maven Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    margin-top: 16px;
}

.ButtonRejectAllModal:hover {
    cursor: pointer;
    background: var(--token-colors-status-interaction-elements, #435a5bda);
    color: var(--token-colors-status-interaction-negative, #FFF);
}

.textH2 {
    font-family: "Maven Pro-SemiBold", Helvetica;
    font-size: 28px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 32px;
    white-space: wrap;
    color: #FFF;
}


@media screen and (max-width: 600px) {
    .cookies-web {
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        position: relative;
        justify-content: center;
        align-items: center;
        background-color: #566f70;
    }

    .textH2 {
        align-self: center;
        justify-self: center;
        font-size: 20px;
        width: 100vw;
        margin: auto;
        text-align: center;
    }
}