.rootContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #51787d;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 100px;
}

.ContainerOurStory {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  gap: 4%;
}

.ContainerOurStory1 {
  width: 48%;
  height: 100%;
}

.ContainerOurStory2 {
  width: 48%;
  height: 600px;
  display: flex;
  justify-content: center;
  background: url('https://bohocasasstorage.blob.core.windows.net/boho-casas/equipa.jpg'), transparent no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.fristPartLabel {
  display: flex;
  align-items: center;
  padding-bottom: 31px;
}

.secondPartLabel {
  padding-left: 20px;
  padding-bottom: 31px;
}

.introduceTeam {
  width: 100%;
  flex-shrink: 0;
  color: var(--plain-text-dark);
  font-family: "Maven Pro", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 140% */
}


.lineVertical {
  position: absolute;
  border-right: 1px solid rgba(255, 255, 255, 0.24);
  /* Cor da borda com opacidade */
  margin-top: 0;
  width: 20px;
  height: 1268px;
  opacity: 0.24;
}

.lineVertical2 {
  position: absolute;
  right: 0px;
  border-left: 1px solid rgba(255, 255, 255, 0.24);
  /* Cor da borda com opacidade */
  margin-top: 0;
  width: 20px;
  height: 1268px;
  opacity: 0.24;
}

.lineHorizontal {
  z-index: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  margin-top: 30px;
  position: absolute;
  width: 100vw;
  height: 49px;
  opacity: 0.24;
}

.playVideo {
  display: flex;
  -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
  background-color: rgba(255, 255, 255, 0.44);
  border-radius: 200px;
  animation: pulsate-fwd 1s ease-in-out infinite both;
}

.rootContainerMobile {
  display: flex;
  flex-direction: column;
  background-color: #51787d;
}

.ContainerOurStory2Mobile {
  display: flex;
  height: 800px;
  width: 100%;
  background: url('https://bohocasasstorage.blob.core.windows.net/boho-casas/equipa.jpg'), lightgray 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: "center";
}

@media screen and (max-width: 480px) {
  .ContainerOurStory2Mobile {
    display: flex;
    height: 400px;
    width: 100%;
    background: url('https://bohocasasstorage.blob.core.windows.net/boho-casas/equipa.jpg'), lightgray 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}


.ContainerOurStory1Mobile {
  display: flex;
  flex-direction: column;

}

.labelMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.fristPartLabelMobile {
  margin-bottom: 0.5rem;
}

.introduceTeamMobile {
  text-align: center;
  text-orientation: sideways;
  padding: 1rem 3rem 1rem 3rem;
}

.introduceTeamMobile p:first-child {
  font-size: larger;
}

.playVideoMobile {
  display: flex;
  -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
  background-color: rgba(255, 255, 255, 0.44);
  border-radius: 200px;
  animation: pulsate-fwd 1s ease-in-out infinite both;
  margin-bottom: 1rem;
}

@media (min-width: 641px) {

  .ContainerOurStory2Mobile {
    margin-bottom: 1rem;
  }
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}