.table {
    text-align: center;
}


.checkCustom {
    width: fit-content;
    font: 13px;
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-form-large .ant-form-item .ant-form-item-label>label {
    color: #545454;
}