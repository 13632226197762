/**
 * Navigation Styles
 *
 * This file contains styles for the navigation components.
 */

/* Styles for the navigation list */
.navlist {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

/* Styles for navigation list items */
.navlist>li {
  /* Adding padding to provide spacing between list items */
  padding: 0.75rem;
  /* Use a relative unit for better responsiveness */
}

/* Styles for the active link */
.activeLink {
  /* Adding a white solid border to the active link for emphasis */
  padding-bottom: 32px;
  border-bottom: 2px solid white;
  /* Use thicker border for better visibility */
  color: white;
  text-decoration: none;
}

/* Styles for navigation links */
.link {
  text-decoration: none;
  color: #fff;
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
}

@media (min-width: 1000px) and (max-width: 1440px) {
  .link {
    font-size: 12px;
  }
}

.link:hover {
  opacity: 0.8;
  transition: 0.5s ease;
  text-decoration: none;
  color: #020505;
  font-weight: bold;
}

.linkTooltip {
  padding-left: 24px;
  text-decoration: none;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
}

.linkTooltip:hover {
  opacity: 0.8;
  transition: 0.5s ease;
  text-decoration: underline;
}

/* Styles for the navigation bar */
.navbar {
  /* Centering the navigation bar content horizontally and vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
  /* Setting a flexible width and a relative height for the navigation bar */
  max-width: 40rem;
  /* Use a relative unit for width */
  height: 1.0625rem;
  /* Use relative units for better responsiveness */
}

/**
 * Styles for the mobile
 */
.containerButtonMobile {
  margin-left: 0;
  transition: all 1s ease-in-out;
}

.buttonMobile {
  border: none;
  background: none;
  box-shadow: none;
  height: 9vw;
}

.buttonMobile img {
  width: 9vw;
  height: 9vw;
}

.imgLogoMobile {
  height: 9vw;
}

.navlistMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  width: 100vw;
  transition: all 1s ease-in-out;
}

.linkMobile {
  text-decoration: none;
  width: 100%;
  text-align: start;
  padding: 1rem;
  margin: 1rem;
  transition: all 1s ease-in-out;
}

.linkMobile a,
.dropdownMobile {
  color: #ffffff;
  text-decoration: none;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

img {
  transition: all 0.3s ease-in-out;
  margin-bottom: 0.3rem;
}

.arrowRotate {
  transform: rotate(90deg);
}

.dropdownMobileItems {
  color: white;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  height: 0;
  transition: all 0.5s ease-in-out;
}

.dropdownMobileItemsActive {
  position: relative;
  margin-left: 1rem;
  margin-top: 1rem;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  height: 25vh;
  transition: all 0.5s ease-in-out;
}

.navbarMobile {
  background-color: #556f70;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 1s ease-in-out;
  align-items: center;
}

.headerNavbarMobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
}

.headerNavbarMobile button {
  background-color: transparent;
  border: none;
}

.linkMobile .MuiAccordion-root {
  background-color: red;
}

.AccordionMobile .MuiAccordion-root {
  background-color: red;
}

@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}