.containerResidential1 {
	background-color: #F2F2F2;
	padding-top: 72px;
	height: 725px;
}

.box {
	height: 702px;
	position: relative;
	width: 1058px;
	margin: auto;
}

.group {
	height: 702px;
	left: 0;
	top: 0;
	width: 1058px;
}

.box .overlap-group-wrapper {
	height: 662px;
	left: 557px;
	position: absolute;
	top: 40px;
	width: 501px;
}

.box .overlap-group-Residential {
	margin-top: 79px;
	height: 662px;
	position: relative;
}

.Link {
	text-decoration: none;
	color: #51787d;
	cursor: pointer;
	font-weight: bold;
}

.Link:hover {
	text-decoration: underline;
}

.box .ImageResidentialInterior1 {
	background-color: #545454;
	background-image: url(https://bohocasasstorage.blob.core.windows.net/boho-casas/SintraCozinha.png);
	background-position: center;
	border-radius: 18.98px;
	height: 440px;
	left: 0;
	position: absolute;
	top: 0;
	width: 397px;
}

.box .ImageResidentialInterior2 {
	background-color: #545454;
	background-image: url(https://bohocasasstorage.blob.core.windows.net/boho-casas/Lisboa2_SALA1.png);
	background-size: cover;
	background-position: center;
	border-radius: 18.98px;
	height: 440px;
	left: 104px;
	position: absolute;
	top: 222px;
	width: 397px;
}

.box .info {
	height: 601px;
	left: 0;
	position: absolute;
	top: 0;
	width: 503px;
}

.box .page-head-instance {
	left: 0 !important;
	position: relative !important;
	top: 0 !important;
}

.box .design-component-instance-node {
	white-space: unset !important;
}

.box .lorem-ipsum-dolor {
	color: #545454;
	font-family: "Maven Pro-Regular", Helvetica;
	font-size: 20px;
	font-weight: 400;
	left: 0;
	letter-spacing: 0;
	line-height: 28px;
	position: relative;
	width: 501px;
}

.container2 {
	width: 1058px;
	margin: auto;
	padding-top: 150px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.boxLeft {
	width: 50%;
	height: 700px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 25px;
	flex-direction: column;
}

.boxRight {
	width: 50%;
	gap: 25px;
	height: 700px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}

.text-wrapper {
	padding-bottom: 10px;
	color: #545454 !important;
	font-family: "Maven Pro-Regular", Helvetica;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 28px;
	width: 500px;
	text-align: justify;
}

.text-wrapper2 {
	padding-bottom: 10px;
	padding-left: 25px;
	color: #545454 !important;
	font-family: "Maven Pro-Regular", Helvetica;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 28px;
	width: 500px;
	text-align: justify;
}


.container3 {
	padding-top: 137px;
}

.BoxButton {
	align-self: center;
	justify-self: center;
	padding-top: 79px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	padding-bottom: 29px;
}