.CalculatorContainer {
  display: grid;
  grid-template-columns: 1.5fr 1fr fit-content(20%);
  grid-template-rows: 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 0px;
  padding: 2rem;
}
.CalculatorStepsContainer {
  grid-area: 1 / 1 / 2 / 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-right: solid 1px rgba(0, 0, 0, 0.206);
}
.CalculatorResultsContainer {
  grid-area: 1 / 2 / 2 / 3;
  overflow: hidden;
}
.step {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.stepNumber {
  background-color: #556f70;
  width: 40px;
  height: 40px;
  color: #ffff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.buttonradiocalculator:hover {
  color: #556f70;
}
.buttonradiocalculator {
  border-radius: 24px;
  width: 130px;
  text-align: center;
}
.radioUn .buttonradiocalculator{
    width: 70px;
}
.radioKitchen .buttonradiocalculator{
    width: 180px;
}
:where(.css-dev-only-do-not-override-mu7yhm).ant-radio-button-wrapper {
  border-start-end-radius: 24px;
  border-end-end-radius: 24px;
}
:where(.css-dev-only-do-not-override-1ejnxy6).ant-radio-button-wrapper:not(
    :first-child
  )::before {
  content: none;
}
:where(.css-dev-only-do-not-override-1ejnxy6).ant-radio-button-wrapper
  > .ant-radio-button {
  border-start-end-radius: 24px;
  border-end-end-radius: 24px;
  border-radius: 24px;
}

.calculatorResult {
  font-size: xx-large;
}
.aboutYourBudgetContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}
.aboutItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.containerDreams {
  margin-top: 2rem;
}
.boxDreams {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.boxDreams p {
  font-weight: bolder;
  margin: 1rem;
}
.CalculatorContainerMobile {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr) fit-content(20%);
  grid-column-gap: 0px;
  grid-row-gap: 10px;
}

.CalculatorStepsContainerMobile {
  grid-area: 1 / 1 / 2 / 2;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
}
.CalculatorResultsContainerMobile {
  grid-area: 2 / 1 / 3 / 2;
  overflow: hidden;
}
.stepMobile {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 3rem;
    overflow: auto;
}
.stepNumberMobile{
    
    position: relative;
    color: #ffff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.stepNumberMobile img{}
.stepNumberMobile div{
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
    
}